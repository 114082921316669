import {useEffect} from 'react'
import {Outlet, Route, Routes} from 'react-router-dom'
import styled from 'styled-components'

import {Login} from './components/Login'

import {toAbsoluteUrl} from '../../../_metronic/helpers'

const StyledContainer = styled.div`
  align-items: center;
  background-image: url(${toAbsoluteUrl('/media/backgrounds/bck_red.png')});
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
`;

const LoginForm = styled.div`
  align-items: center;
  background: #ffffff;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 3px 17px 37px 4px rgba(106, 153, 73, 0.6);
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 80%;
  color: #717171;

  .logo {
    height: 65px;
    margin-bottom: 100px;
    width: auto;
  }

  h3 {
    color: #717171;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
  }

  p {
    color: #717171;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 32px;
  }

  @media only screen and (min-width: 768px) {
    width: 50%;

    .logo {
      height: 90px;
    }
  }
`

const Title = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  text-align: center;
  width: 50%;
  padding: 48px;
  padding-top: 168px;

  p {
    color: #FFFFFF;
    font-size: 50px;
    font-weight: 600;
    line-height: 75px;
    margin: 0;
    width: 450px;
  }
`;

function AuthLayout() {
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])

  return (
    <StyledContainer>
      <LoginForm>
        <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-aristarc.png')} className='logo' />
        <h3>Connexion</h3>
        <p>Entrez votre login et mot de passe</p>
        <Outlet />
      </LoginForm>
      <Title>
        <p>Gestion des Registres de Courriers</p>
      </Title>
    </StyledContainer>
  )
}

function AuthPage() {
  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
  )
}

export {AuthPage}
